
/* injects from baggage-loader */

'use strict';

export default function (app) {
  app.service('Navigation', function ($log) {

    this.current = undefined;
    this.state = {};
    this.intendedPath = undefined;
    this.navGuard = {};

    this.setupMenu = function (config) {
      this.current = config;
    };

    this.teardownMenu = function () {
      this.current = undefined;
    };

    this.setCurrentState = function (state) {
      this.state = state;
    }

    this.currentState = {
      getTitle: () => {
        if (this.state.data) {
          return this.state.data.navTitle || this.state.data.title || '';
        } else {
          return '';
        }
      }
    }

    this.setIntendedPath = path => {
      this.intendedPath = path;
    }

    this.getIntendedPath = clear => {
      let path = this.intendedPath;
      if (clear) {
        this.intendedPath = undefined;
      }
      return path;
    }

    // Navigation guard system
    this.setGuard = guard => this.navGuard = guard;
    this.releaseGuard = () => this.navGuard = { fn: angular.noop };
    this.checkGuard = () => {
      if (this.navGuard.fn()) {
        let answer = confirm(this.navGuard.message)
        if (!answer) {
          return false;
        }
      }
      return true;
    }

  });
}

