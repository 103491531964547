
/* injects from baggage-loader */

'use strict';

const shared = angular.module('core.shared', []);

import constants from './constants';

import documentCodeMaskDirective from './directives/documentcodemask.directive';

import truncateFilter from './filters/truncate.filter';

import AlertService from './services/alerts.service';
import OpenLinkService from './services/openlink.service';
import ProductDataService from './services/productdata.service';
import NavigationService from './services/navigation.service';
import RecentlyViewedService from './services/recentlyviewed.service';
import ValidationService from './services/validation.service';

import ParseService from './services/parse.service';
import ParseProductService from './services/parse/product.service';
import ParseCompanyService from './services/parse/company.service';
import ParseDocumentService from './services/parse/document.service';
import ParseActivityService from './services/parse/activity.service';
import ParseDocumentRequestService from './services/parse/documentrequest.service';
import ParseProductServiceUpdateService from './services/parse/serviceupdates.service';
import ParseProductGroupService from './services/parse/productgroup.service';
import ParseUserService from './services/parse/user.service';
import ParseAccountInvitationService from './services/parse/accountinvitation.service';
import ParseProductFieldLogService from './services/parse/fieldlog.service';
import ParseProductFieldLogDocumentService from './services/parse/fieldlogdocument.service';

// Directives
documentCodeMaskDirective(shared);

// Services
AlertService(shared);
OpenLinkService(shared);
ProductDataService(shared);
NavigationService(shared);
RecentlyViewedService(shared);
ValidationService(shared);

ParseService(shared);
ParseProductService(shared);
ParseCompanyService(shared);
ParseDocumentService(shared);
ParseActivityService(shared);
ParseDocumentRequestService(shared);
ParseProductServiceUpdateService(shared);
ParseProductGroupService(shared);
ParseUserService(shared);
ParseAccountInvitationService(shared);
ParseProductFieldLogService(shared);
ParseProductFieldLogDocumentService(shared);

// Filters
truncateFilter(shared);

// Others
constants(shared);

export default shared;