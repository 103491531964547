
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('OpenLink', function () {

		this.inNewTab = url => {

			// Create a download link
			let link = document.createElement("a");
			link.href = url;

			link.target = "_blank";
			link.style = "visibility:hidden";

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

		}

	});
}

