
/* injects from baggage-loader */

'use strict';

export default class RecentlyViewedController {

	constructor($log, $window, localStorageService) {
		'ngInject';

		this.$log = $log;
		this.$window = $window;
		this.localStorage = localStorageService;

		this.gridView = false;
		this.listView = false;

	}

	$onInit() {

		// Fetch view preference from localStorage
		let view = this.localStorage.get('listPreference');
		this.setView(view);

		this.$log.debug(this.items)
	}

	openProductLink(product) {
		return `${this.$window.location.origin}/company/${product.id}`;
	}

	setView(view) {
		if (view === 'grid') {
			this.listView = false;
			this.gridView = true;
		} else {
			this.gridView = false;
			this.listView = true;
		}
		// Save listPreference in localStorage
		if (view) {
			this.localStorage.set('listPreference', view);
		}
	}

}
