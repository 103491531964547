
/* injects from baggage-loader */

'use strict';

import GroupController from './group.controller';
import GroupTemplate from './group.html';

export default class GroupComponent {
		constructor() {
				this.templateUrl = GroupTemplate;
				this.controller = GroupController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					ctx: '<'
				};
		}
}
