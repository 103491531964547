
/* injects from baggage-loader */

'use strict';

import InvitationsComponent from './invitations.component';

const InvitationsPageModule = angular.module('invitations-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    // $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('invitations', {
        url: '/invitations',
        component: 'invitations',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('invitations', new InvitationsComponent());

export default InvitationsPageModule;
