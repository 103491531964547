
/* injects from baggage-loader */

'use strict';

import GroupComponent from './group.component';

const GroupPageModule = angular.module('group-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    // $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('group', {
        url: '/group/{id}',
        component: 'group',
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          ctx: function (Company, Product, ProductGroup, $transition$) {
            return ProductGroup.get($transition$.params('to').id)
              .catch(err => {
                return {
                  error: 'Product group not found'
                }
              })
          }
        }
      })
      .state('securegroup', {
        url: '/securegroup/{id}',
        component: 'group',
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          ctx: function (Company, Product, ProductGroup, $transition$) {
            return ProductGroup.get($transition$.params('to').id, 'private')
              .catch(err => {
                return {
                  error: 'Product group not found'
                }
              })
          }
        }
      });
  })
  .component('group', new GroupComponent());

export default GroupPageModule;
