
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('RecentlyViewed', function ($log, localStorageService) {

		const storageKey = 'recentlyViewed';

		// ================================
		// Private functions 
		// ================================

		function getLocalData() {
			return localStorageService.get(storageKey) || [];
		}

		function getValue(key, obj) {
			return key.split('.').reduce((o, i) => o[i], obj)
		}

		function reduceItem(item, keys) {
			return keys.reduce((final, key) => {
				final[key.replace('.', '_')] = key.includes('.') ? getValue(key, item) : item[key];
				return final;
			}, {});
		}

		function itemExists(item, list) {
			return list.find(i => i.id === item.id) ? true : false;
		}

		function removeItem(item, list) {
			let index = list.findIndex(i => i.id === item.id);
			if (index > -1) {
				$log.debug('remove item from pos', index, item)
				list.splice(index, 1)
			}
			return list;
		}

		// ================================
		// Public Functions
		// ================================

		this.getList = () => {
			return getLocalData();
		}

		this.add = item => {

			$log.debug('add item', item);
			let recents = getLocalData();
			$log.debug('recently viewed', recents);

			item = reduceItem(item, ['id', 'name', 'model_num', 'image', 'company.name']);
			$log.debug(item)

			if (itemExists(item, recents)) {
				// Remove the item from it's currentl place
				removeItem(item, recents);
			} 

			// Unshift the item to the top of the array
			recents.unshift(item);

			$log.debug('updated recents', recents);

			// Save the new list to local storage
			localStorageService.set(storageKey, recents);

		}

		this.clear = () => {
			localStorageService.set(storageKey, []);
		}

	});
}

