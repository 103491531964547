
/* injects from baggage-loader */

'use strict';

import PasswordResetController from './password-reset.controller';
import PasswordResetTemplate from './password-reset.html';

export default class PasswordResetComponent {
		constructor() {
				this.templateUrl = PasswordResetTemplate;
				this.controller = PasswordResetController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
