
/* injects from baggage-loader */

'use strict';

export default class FieldLogModalController {

	constructor($log, $timeout, Upload, Product, FieldLog, FieldLogDocument, FILE_UPLOAD_URL) {
		'ngInject';

		this.FILE_UPLOAD_URL = FILE_UPLOAD_URL;
		this.$log = $log;
		this.$timeout = $timeout;
		this.Upload = Upload;
		this.Product = Product;
		this.FieldLog = FieldLog;
		this.FieldLogDocument = FieldLogDocument;

		this.fieldLog = {};
		this.field_log_form = {
			name: '',
			company_name: '',
			email: '',
			note: ''
		};

		this.saving = false;
		this.files = [];
		this.pendingFiles = [];
		this.documents = [];
		this.maxNumberOfFiles = 10;

	}

	async $onInit() {
		this.product = this.resolve.context.product;
		this.$log.debug('Product', this.product);

		// Creating a new service update
		this.fieldLog = new this.FieldLog({
			product: this.product
		});

	}

	getMaxNumberOfUploadsRemaining() {
		return this.maxNumberOfFiles - this.files.length;
	}

	isMaxFilesUploaded() {
		return this.maxNumberOfFiles === this.files.length;
	}

	generateFilePreview(file) {
		if (file) {
				const reader = new FileReader();
				reader.onload = e => {
					file.preview = e.target.result;
					this.$timeout();
				};
				reader.readAsDataURL(file);
		}
}

	handleFiles() {
		if (this.pendingFiles.length) {
			this.$log.debug('Added files', this.pendingFiles);
			while (this.pendingFiles.length > 0) {
				const file = this.pendingFiles.shift();
				this.generateFilePreview(file);
				this.files.push(file);
			}
		}
		this.$log.debug('Files', this.files);
	}

	async processFiles() {

		this.errorMsg = '';
		this.$log.debug('Processing files', this.files)

		for (let file of this.files) {

			try {

				// Sanitize the filename
				let filename = file.name.split('.');
				let ext = filename.pop();
				filename = filename.join('_').replace(/[^a-z0-9]/gi, '_').toLowerCase();
				filename = `${filename}.${ext}`;

				// Create a new "Document"
				let document = new this.FieldLogDocument({
					label: filename,
					original_name: file.name,
					size: file.size,
					type: file.type,
					fieldLog: this.fieldLog
				});
				await document.save(null, {
					cascadeSave: false
				});

				document.uploaded = false;
				document.uploading = false;
				document.animateUpload = false;
				document.progressPercentage = 0;
				file.document = document;

				// Push the document to the documents array
				this.documents.push(document);

				this.$log.debug('Uploading file', file, document);
				await this.uploadFile(file, document);
				this.$timeout();

			} catch (err) {
				this.$log.debug('file processing error', err);
				this.error_msg = err.toString().replace('ParseError: 141', '').trim();
				this.$timeout();
			}

		}

	}


	/**
	 * Removes a document from the tracked documents array and 
	 * adds it to the array of documents to delete
	 * @param {Object} doc 
	 */
	removeDocument(doc) {

		// Remove the document from the "documents" array
		this.documents.splice(this.documents.indexOf(doc), 1);

	}

	uploadFile(file, document) {
		this.$log.debug('Uploading file to', this.FILE_UPLOAD_URL, file);
		document.showProgress = true;
		document.uploading = true;
		this.$timeout();
		return this.Upload.upload({
			url: this.FILE_UPLOAD_URL,
			data: {
				files: [file],
				documentId: document.id,
				fieldLogId: this.fieldLog.id,
				type: "fieldLog"
			}
		}).then(async resp => {
			this.$log.debug('Success ' + resp.config.data.files[0].name + 'uploaded. Response: ' + resp.data);
			// Uploading complete
			document.uploading = false;
			document.uploaded = true;
			document.animateUpload = false;
			this.$timeout();
			return true;
		}, resp => {
			this.$log.debug('Error status: ' + resp.status);
			document.error = 'An error occured';
			document.progressPercentage = 100;
			document.animateUpload = true;
			this.$timeout();
		}, evt => {
			if (evt.config.data.files) {
				let progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				document.progressPercentage = progressPercentage;
				if (progressPercentage === 100) {
					document.uploading = false;
					document.animateUpload = true;
					this.$timeout();
				}
			}
		});
	}

	async submit() {

		if (!this.fieldLog.name) {
			this.error_msg = 'Missing "Name"';
			return false;
		}

		if (!this.fieldLog.company_name) {
			this.error_msg = 'Missing "Company"';
			return false;
		}

		if (!this.fieldLog.email) {
			this.error_msg = 'Missing "Email"';
			return false;
		}

		this.saving = true;
		this.error_msg = '';

		try {

			// Save the Field Log
			await this.fieldLog.save(null, {
				cascadeSave: false
			});
			this.$log.debug('Field log created', this.fieldLog);

			// Upload Field Log Documents
			await this.processFiles();

			// Fetch the updated service update object
			await this.fieldLog.fetch();

			this.close({
				$value: {
					fieldLog: this.fieldLog
				}
			});

		} catch (err) {
			this.saving = false;
			this.$log.error(err);
			this.error_msg = 'Something went wrong';
			this.$timeout();
		}

	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
