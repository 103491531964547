
/* injects from baggage-loader */

'use strict';

import CodeNotFoundController from './codenotfound.controller';
import CodeNotFoundTemplate from './codenotfound.html';

export default class CodeNotFoundComponent {
		constructor() {
				this.templateUrl = CodeNotFoundTemplate;
				this.controller = CodeNotFoundController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
