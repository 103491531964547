
/* injects from baggage-loader */

'use strict';

export default class PasswordResetController {

	constructor($log, $timeout, User) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.User = User;

		this.user= {
			email: ''
		}
		this.request_sending = false;
		this.request_sent = false;

	}

	$onInit() {

	}

	requestPasswordReset() {

		// Verify email address fits regex model

		this.request_sending = true;
		this.User.resetPassword(this.user.email)
			.then(res => {
				this.$log.debug(res);

				// Show the user a confirmation and instructions to check their email for a link
				this.request_sent = true;
				this.$timeout();

			});

	}

}
