
/* injects from baggage-loader */

'use strict';

import ViewFieldLogController from './viewfieldlog.controller';
import ViewFieldLogTemplate from './viewfieldlog.html';

export default class ViewFieldLogComponent {
		constructor() {
				this.templateUrl = ViewFieldLogTemplate;
				this.controller = ViewFieldLogController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					fieldLog: '<',
					attachments: '<',
					productInfo: '<'
				};
		}
}
