
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ServiceUpdates', function (Parse) {

		let schema = [
			'text',
			'issuedAt',
			'document'
		];

		let ServiceUpdate = Parse.Object.extend('ProductServiceUpdates', {
			// Instance methods

		}, {
			// Class methods

			find(product) {
				return product.relation('service_updates')
					.query().include(['document']).descending('issuedAt').limit(1000).find()
			}

		});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(ServiceUpdate.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return ServiceUpdate;

	});
}

