
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Validation', function () {

    this.validateEmail = val => {
      if (!val.match(/\S+@\S+\.\S+/)) { // Jaymon's / Squirtle's solution
        return false;
      }
      if (val.indexOf(' ') !== -1 || val.indexOf('..') !== -1) {
        return false;
      }
      return true;
    }

	});
}
