
/* injects from baggage-loader */

'use strict';

import MyGroupsController from './my-groups.controller';
import MyGroupsTemplate from './my-groups.html';

export default class MyGroupsComponent {
		constructor() {
				this.templateUrl = MyGroupsTemplate;
				this.controller = MyGroupsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					groups: '<'
				};
		}
}
