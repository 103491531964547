
/* injects from baggage-loader */

'use strict';

import RecentlyViewedController from './recently-viewed.controller';
import RecentlyViewedTemplate from './recently-viewed.html';

export default class RecentlyViewedComponent {
		constructor() {
				this.templateUrl = RecentlyViewedTemplate;
				this.controller = RecentlyViewedController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					items: '<'
				};
		}
}
