
/* injects from baggage-loader */

'use strict';

export default class ServiceUpdatesModalController {

	constructor($log, $timeout, ServiceUpdates) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.ServiceUpdates = ServiceUpdates;
		this.doneLoading = false;

		this.serviceUpdates = [];

	}

	async $onInit() {
		this.product = this.resolve.context.product;

		this.ServiceUpdates.find(this.product).then(serviceUpdates => {
			this.serviceUpdates = serviceUpdates;
			this.doneLoading = true;
			this.$timeout();
		})
	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
