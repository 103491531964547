
/* injects from baggage-loader */

'use strict';

import ProductComponent from './product.component';

const ProductPageModule = angular.module('product-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    // $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('product', {
        url: '/company/{id}?event',
        component: 'product',
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          ctx: function (Company, ProductDocument, Product, $transition$) {
            return Product.get($transition$.params('to').id)
              .catch(err => {
                console.error(err);
                return {
                  error: 'Product not found'
                }
              })
          }
        }
      });
  })
  .component('product', new ProductComponent());

export default ProductPageModule;
