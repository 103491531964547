
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Activity', function ($log, Parse, ProductDocument, Product) {

		let schema = [
			'type',
			'code',
			'product',
			'document',
			'root'
		];

		let activity = Parse.Object.extend('Activity');

		let Activity = Parse.Object.extend('Activity', {
			// Instance methods

		}, {
				// Class methods

				logDownloadEvent(options = {}) {
					$log.log(options)
					let event = new activity();
					event.set('type', 'document-dl');
					event.set('product', (new Product({ id: options.product_id })).toPointer());
					event.set('document', (new ProductDocument({ id: options.document_id })).toPointer());
					if (typeof options.root === 'string') {
						event.set('root', (new Activity({ id: options.root })).toPointer());
					}
					event.save().then(e => {
						$log.log(e)
					})
				}

			});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(Activity.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return Activity;

	});
}

