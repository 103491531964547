
/* injects from baggage-loader */

'use strict';

import PasswordResetComponent from './password-reset.component';

const PasswordResetPageModule = angular.module('password-reset-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    // $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('password-reset', {
        url: '/password-reset',
        component: 'passwordReset',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .run(function ($transitions, $location, User) {

    // Handle entrance to password-reset page when user is already logged in
    $transitions.onEnter({ to: 'password-reset' }, transition => {

      if (User.isUserLoggedIn() === true) {
        $location.path('/my-groups');
        return transition.router.stateService.target('my-groups');
      }

    });

  })
  .component('passwordReset', new PasswordResetComponent());

export default PasswordResetPageModule;
