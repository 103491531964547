
/* injects from baggage-loader */

/*global NODE_ENV*/
'use strict';

function config($logProvider, $compileProvider, localStorageServiceProvider) {
	'ngInject';

	$logProvider.debugEnabled(true);

	if (NODE_ENV === 'production') {
		$logProvider.debugEnabled(false);
		$compileProvider.debugInfoEnabled(false);
	}

	localStorageServiceProvider
    .setPrefix('hrscWeb')
    .setStorageType('localStorage')
    .setNotify(false, false)

}

export default config;