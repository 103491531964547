
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProductDocument', function (Parse) {

		let schema = [
			'label',
			'type',
			'file',
			'protected',
			'isGroup',
			'documents',
			'isCertificate',
			'isExternalUrl'
		];
		
		let ProductDocument = Parse.Object.extend('Document', {
			// Instance methods

		}, {
				// Class methods

				fetchFileURL(id) {
					return Parse.Cloud.run('fetchFileURL', { id });
				},

				verifyKey(id, key) {
					return Parse.Cloud.run('verifyDocumentKey', { id, key });
				},

				// Function the could unlock multiple documents at once 
				// verifyKey(ids, key) {
				// 	return Parse.Cloud.run('verifyDocumentKeys', { ids, key });
				// },

				refreshSignatures(keys) {
					return Parse.Cloud.run('refreshSignatures', { keys });
				}

			});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(ProductDocument.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		// Add "id" for "objectId" getter
		// Object.defineProperty(ProductDocument.prototype, 'id', {
		// 	get: function () { return this.get('objectId'); },
		// });

		return ProductDocument;

	});
}

