
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProductGroup', function ($log, $q, Parse) {
		
		let schema = [
			'name',
			'description',
			'scope',
			'online',
			'products',
			'group_id',
			'security',
			'users',
			'company'
		];

		let ProductGroup = Parse.Object.extend('ProductGroup', {
			// Instance methods

		}, {
				// Class methods


				/**
				 * Retrieve an access group by ID
				 */
				get(id, scope = 'public') {
					return Parse.Cloud.run('fetchAccessGroup', { id, scope });
				},

					/**
				 * Query for products
				 * @param {Object} options 
				 */
				query(options = {}) {
					let d = $q.defer();
					let query = new Parse.Query(this);
					query.descending('name');
					for (let key in options) {
						if (typeof options[key].key !== 'undefined' && typeof options[key].value !== 'undefined') {
							query[key](options[key].key, options[key].value);
						} else {
							query[key](options[key]);
						}
					}
					d.resolve(query.find());
					return d.promise;
				},


			});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(ProductGroup.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return ProductGroup;


	});
}

