
/* injects from baggage-loader */

'use strict';

export default class ProductController {

	constructor($log, $stateParams, $uibModal, Alerts, ProductDocument, OpenLink, Activity, ProductData, RecentlyViewed) {
		'ngInject';

		this.$log = $log;
		this.$stateParams = $stateParams;
		this.$uibModal = $uibModal;
		this.ProductDocument = ProductDocument;
		this.alerts = Alerts;
		this.OpenLink = OpenLink;
		this.Activity = Activity;
		this.ProductData = ProductData;
		this.RecentlyViewed = RecentlyViewed;


		this.error = '';
		this.modals = {};
		this.document_keys = {};
		this.documentOrder = [];
		this.serviceUpdates = [];
		this.certifications = [];

		// Grab event ID from the query string params
		this.product_id = this.$stateParams.id;
		this.event_id = this.$stateParams.event;

		// Product saved (bookmark)
		this.bookmarked = false;

	}

	$onInit() {

		// Check for error
		if (this.ctx.error) {
			this.error = this.ctx.error;
			return false;
		}

		this.product = this.ctx.product;
		this.documents = this.ctx.documents;
		this.company = this.product.company;
		this.serviceUpdates = this.ctx.serviceUpdates;

		this.RecentlyViewed.add(this.product);

		// this.$log.debug(this.ctx);
		// this.$log.debug('Event ID:', this.event_id);

		// Find all certifications in documents array
		this.certifications = this.documents.filter(doc => doc.isCertificate);

		// Build document groups
		this.documentOrder = this.product.document_order
			.map(id => {
				let item = this.documents.find(doc => doc.id === id || doc.objectId === id);
				if (item && item.isCertificate) {
					// filter out certification docs from main group
					return;
				}
				if (item && item.isGroup) {
					item.documents = item.documents.map(_id => this.documents
						.find(doc => doc.id === _id || doc.objectId === _id)).filter(doc => doc !== undefined)
				}
				return item;
			})
			.filter(doc => doc !== undefined)

		// Fetch product data from local storage
		this.document_keys = this.ProductData.fetchDocumentKeys(this.product_id);

		// Verify document signatures
		if (Object.keys(this.document_keys).length) {
			this.ProductDocument.refreshSignatures(this.document_keys)
				.then(keys => {
					this.document_keys = keys;
				});
		}

		// Log "product-view" event
		// NOTE: prevent view events on scans and page refreshes
		// if (!document.referrer.includes('code.machinedocument.com') && document.referrer !== window.location.href) {
			if (!this.event_id) {
			(new this.Activity({
				type: 'product-view',
				product: this.product
			})).save();
		}

	}

	openModal(id) {
		this.modals[id] = true;
	}

	closeModal(id) {
		this.modals[id] = false;
	}

	getServiceEmail() {
		return `mailto:${this.product.service_email}`;
	}

	getDocumentURL(doc) {
		return doc.protected ? `${doc.url}?event=${this.event_id || ''}&p=${this.product_id}&key=${this.getDocumentKey(doc).key}&sig=${this.getDocumentKey(doc).sig}` : `${doc.url}?event=${this.event_id || ''}&p=${this.product_id}`;
	}

	getDocumentKey(doc) {
		return this.document_keys[doc.id || doc.objectId] || '';
	}

	bookmarkProduct() {
		this.bookmarked = true;
		alert('Feature coming soon!');
	}

	requestDocument(event, doc) {

		// Stop link open if protected document and key is not present and valid 
		if (doc.protected && !this.getDocumentKey(doc)) {

			// Stop link from opening
			event.preventDefault();

			// Open modal to ask user to enter pin or request access
			this.openProtectedDocumentModal(doc);

			return false;
		}

		// Log document download event
		// this.Activity.logDownloadEvent({
		// 	root: this.event_id,
		// 	document_id: doc.objectId || doc.id,
		// 	product_id: this.product_id
		// });

	}


	openProtectedDocumentModal(doc) {

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'documentRequestModalComponent',
			resolve: {
				context: () => {
					return {
						// ids: this.documents.filter(doc => doc.protected === true).map(doc => doc.objectId || doc.id),
						company_name: this.company.name,
						document_id: doc.id || doc.objectId,
						document: doc,
						product: this.product,
						event_id: this.event_id
					};
				}
			}
		});


		modalInstance.result.then(async res => {

			// Set the document key
			this.document_keys[res.document_id] = {
				key: res.key_hash,
				sig: res.sig
			};

			// Update session saved document keys
			this.ProductData.updateDocumentKeys(this.product_id, {
				[res.document_id]: {
					key: res.key_hash,
					sig: res.sig
				}
			});

		}, angular.noop);

	}

	clearDocumentKeys() {

		// Clear any document keys applied for this product
		this.document_keys = {};

		// Clear keys in local storage
		this.ProductData.clearDocumentKeys(this.product_id);

	}

	openServiceUpdatesModal() {

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'serviceUpdatesModal',
			resolve: {
				context: () => ({ product: this.product })
			}
		});

		modalInstance.result.then(angular.noop, angular.noop);

	}

	openFieldLogListModal() {

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'fieldLogListModal',
			resolve: {
				context: () => ({ product: this.product })
			}
		});

		modalInstance.result.then(angular.noop, angular.noop);

	}

	openFieldLogFormModal() {

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'fieldLogModal',
			resolve: {
				context: () => ({ product: this.product })
			}
		});


		modalInstance.result.then(async res => {

			// TODO: do something after successful
			
		}, angular.noop);

	}

}
