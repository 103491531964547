
/* injects from baggage-loader */

'use strict';

import $ from 'jquery';

export default function (app) {

	app.directive('documentCodeMask', DocumentCodeMaskDirective);

	function DocumentCodeMaskDirective() {
		'ngInject';

		function formatCode(value) {
			let result = value;

			let code = value ? value.toString() : '';
			if (code.length > 4) {
				result = code.substr(0, 4) + '-';
				if (code.length > 6) {
					result += code.substr(4, 4);
				}
				else {
					result += code.substr(4);
				}
			}

			return result;
		}

		return {
			restrict: 'A',
			link: linkFn,
			require: 'ngModel'
		};

		function linkFn(scope, element, attrs, ngModel) {

			ngModel.$formatters.push(function (value) {
				return formatCode(value);
			});

			// clean output as digits
			ngModel.$parsers.push(function (value) {
				let cursorPosition = element[0].selectionStart;
				let oldLength = value.toString().length;
				let nonDigits = /[^0-9]/g;
				let intValue = value.replace(nonDigits, '');
				if (intValue.length > 9) {
					intValue = intValue.substr(0, 9);
				}
				let newValue = formatCode(intValue);
				ngModel.$setViewValue(newValue);
				ngModel.$render();
				element[0].setSelectionRange(cursorPosition + newValue.length - oldLength, cursorPosition + newValue.length - oldLength);
				return intValue;
			});

			// // Hide the text by default
			// $(element).attr('style', 'text-security:disc; -webkit-text-security:disc;');

			// // Reveal the text on focus
			// element.bind('focus', function (e) {
			// 	$(element).attr('style', '');
			// });

			// // Hide the text on lost focus
			// element.bind('blur', function (e) {
			// 	$(element).attr('style', 'text-security:disc; -webkit-text-security:disc;');
			// });
			
		}

	}
}