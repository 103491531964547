
/* injects from baggage-loader */

'use strict';

import MyGroupsComponent from './my-groups.component';

const MyGroupsPageModule = angular.module('my-groups-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    // $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('my-groups', {
        url: '/my-groups',
        component: 'myGroups',
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          groups: function(ProductGroup) {
            return ProductGroup.query();
          }
        }
      });
  })
  .component('myGroups', new MyGroupsComponent());

export default MyGroupsPageModule;
