
/* injects from baggage-loader */

'use strict';

import SettingsComponent from './settings.component';

const SettingsPageModule = angular.module('settings-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    // $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('settings', {
        url: '/settings',
        component: 'settings',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('settings', new SettingsComponent());

export default SettingsPageModule;
