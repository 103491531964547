
/* injects from baggage-loader */

'use strict';

import InvitationsController from './invitations.controller';
import InvitationsTemplate from './invitations.html';

export default class InvitationsComponent {
		constructor() {
				this.templateUrl = InvitationsTemplate;
				this.controller = InvitationsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
