
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Company', function (Parse) {

		let schema = [
			'name',
			'website',
			'facebook',
			'instagram',
			'twitter',
			'youtube',
			'service_email',
			'company_id'
		];
		
		let Company = Parse.Object.extend('Company', {
			// Instance methods

		}, {
				// Class methods

			});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(Company.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return Company;

	});
}

