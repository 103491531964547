
/* injects from baggage-loader */

'use strict';

import FieldLogModalComponent from './fieldlogmodal.component';
import './fieldlogmodal.scss';

const FieldLogModalModule = angular.module('field-log-modal-module', []);

FieldLogModalModule
    .component('fieldLogModal', new FieldLogModalComponent());

export default FieldLogModalModule;

