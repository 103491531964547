
/* injects from baggage-loader */

'use strict';

import FieldLogModalController from './fieldlogmodal.controller';
import FieldLogModalTemplate from './fieldlogmodal.html';

export default class FieldLogModalComponent {
		constructor() {
				this.templateUrl = FieldLogModalTemplate;
				this.controller = FieldLogModalController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					resolve: '<',
					close: '&',
					dismiss: '&'
				};
		}
}
