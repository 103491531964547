
/* injects from baggage-loader */

'use strict';

export default class GroupController {

	constructor($log, $location, $window, localStorageService, Parse) {
		'ngInject';

		this.$log = $log;
		this.$location = $location;
		this.$window = $window;
		this.localStorage = localStorageService;


		this.group;
		this.products = [];
		this.companyName;

		this.gridView = false;
		this.listView = false;

		this.Activity = Parse.Object.extend('Activity');

	}

	$onInit() {

		// Check for error
		if (this.ctx.error) {
			this.error = this.ctx.error;
			return false;
		}

		this.group = this.ctx.group;
		this.companyName = this.group.company.get('name');

		this.products = this.ctx.products.map(product => {
			product.image_url = product.image ? product.image.url() : null;
			return product;
		});

		// Fetch view preference from localStorage
		let view = this.localStorage.get('listPreference');
		this.setView(view);

		// Log "group-view" event
		(new this.Activity({
      type: 'group-view',
      group: this.group
    })).save();

	}

	openProductLink(product) {
		return `${this.$window.location.origin}/product/${product.id}`;
	}

	setView(view) {
		if (view === 'grid') {
			this.listView = false;
			this.gridView = true;
		} else {
			this.gridView = false;
			this.listView = true;
		}
		// Save listPreference in localStorage
		if (view) {
			this.localStorage.set('listPreference', view);
		}
	}

}
