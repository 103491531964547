
/* injects from baggage-loader */

'use strict';

import ActionsAcceptInviteComponent from './actions.accept-invite.component';

const ActionsAcceptInvitePageModule = angular.module('actions-accept-invite-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    // $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('accept-invite', {
        url: '/actions/accept-invite?invitationId',
        component: 'actionsAcceptInvite',
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          invitation: function(AccountInvitation, $transition$, $log) {
            return AccountInvitation.getByInvitationCode($transition$.params('to').invitationId)
          },
          company: function(invitation, Company) {
            return invitation ? invitation.get('company').fetch() : null;
          },
          group: function(invitation, ProductGroup) {
            return invitation ? invitation.get('group') : null;
          }
        }
      });
  })
  .component('actionsAcceptInvite', new ActionsAcceptInviteComponent());

export default ActionsAcceptInvitePageModule;
