
/* injects from baggage-loader */

'use strict';

export default class HeaderController {
    constructor($log, $rootScope, Alerts, Navigation, User, $state) {
        'ngInject';

        this.$log = $log;
        this.$state = $state;
        this.alerts = Alerts;
        this.navigation = Navigation;
        this.User = User;
        this.$rootScope = $rootScope;

        this.navCollapsed = true;
        // this.$rootScope.theme.dark = localStorage.getItem('useDarkTheme');

    }

    $onInit() {

        // this.alerts.add({ type: 'success', msg: 'Invitation accepted' });

    }

    toggleDropdown(status) {
        this.navCollapsed = typeof status !== 'undefined' ? status : !this.navCollapsed;
    }

    getUser() {
        return this.User.current();
    }

    isUserLoggedIn() {
        return this.User.isUserLoggedIn();
    }

    logOut() {
        return this.User.logout();
    }

    getHomePath() {
        return this.isUserLoggedIn() ? '/home' : '/login';
    }
}