
/* injects from baggage-loader */

'use strict';

import BookmarksComponent from './bookmarks.component';

const BookmarksPageModule = angular.module('bookmarks-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    // $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('bookmarks', {
        url: '/bookmarks',
        component: 'bookmarks',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('bookmarks', new BookmarksComponent());

export default BookmarksPageModule;
