
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProductData', function (localStorageService) {

		this.fetchDocumentKeys = product_id => {

			if (localStorageService.isSupported) {

				// Fetch existing product data
				let data = localStorageService.get('productData') || {};
				let product = data[product_id] || {};

				return product.documentKeys || {};

			} else {
				return {};
			}

		}

		this.updateDocumentKeys = (product_id, key_pair) => {

			// Update document keys for this product
			if (localStorageService.isSupported) {

				// Fetch existing product data
				let data = localStorageService.get('productData') || {};

				// Update or create product data
				if (typeof data[product_id] !== 'object') {
					data[product_id] = {
						documentKeys: {}
					}
				}

				// Set the document key
				data[product_id].documentKeys = Object.assign(data[product_id].documentKeys, key_pair);

				// Update local storage
				localStorageService.set('productData', data);

			}

		}

		this.clearDocumentKeys = product_id => {

			// Update document keys for this product
			if (localStorageService.isSupported) {

				// Fetch existing product data
				let data = localStorageService.get('productData') || {};

				// Reset document keys object product data
				if (data[product_id] && data[product_id].documentKeys) {
					data[product_id].documentKeys = {}
				}

				// Update local storage
				localStorageService.set('productData', data);

			}

		}

	});
}

