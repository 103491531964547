
/* injects from baggage-loader */

'use strict';

import SettingsController from './settings.controller';
import SettingsTemplate from './settings.html';

export default class SettingsComponent {
		constructor() {
				this.templateUrl = SettingsTemplate;
				this.controller = SettingsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
