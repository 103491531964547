
/* injects from baggage-loader */

'use strict';

import FieldLogListModalComponent from './fieldloglistmodal.component';
import './fieldloglistmodal.scss';

const FieldLogListModalModule = angular.module('field-log-list-modal-module', []);

FieldLogListModalModule
    .component('fieldLogListModal', new FieldLogListModalComponent());

export default FieldLogListModalModule;

