
/* injects from baggage-loader */

'use strict';

export default class FieldLogListModalController {

	constructor($log, $timeout, $window, $uibModal, FieldLog) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.$window = $window;
		this.ModalService = $uibModal;
		this.FieldLog = FieldLog;
		this.doneLoading = false;

		this.fieldLogs = [];

	}

	async $onInit() {
		this.product = this.resolve.context.product;

		this.FieldLog.find(this.product).then(fieldLogs => {
			this.fieldLogs = fieldLogs;
			this.doneLoading = true;
			this.$timeout();
		})
	}

	openFieldLogView(fieldLog) {
		return `${this.$window.location.origin}/product/${this.product.id}/fieldlog/${fieldLog.id}`;
	}

	openFieldLogFormModal() {

		let modalInstance = this.ModalService.open({
			animation: true,
			component: 'fieldLogModal',
			resolve: {
				context: () => ({ product: this.product })
			}
		});


		modalInstance.result.then(async res => {

			// TODO: do something after successful
			
		}, angular.noop);

	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
