
/* injects from baggage-loader */

'use strict';

import headerModule from './components/header/header.module';
import footerModule from './components/footer/footer.module';
import documentRequestModalModule from './components/documentRequestModalComponent/documentrequestmodalcomponent.module';
import serviceUpdatesModalModule from './components/serviceUpdatesModal/serviceupdatesmodal.module';
import fieldLogModalComponent from './components/fieldLogModal/fieldlogmodal.module';
import fieldLogListModalComponent from './components/fieldLogListModal/fieldloglistmodal.module';

const components = [
	headerModule,
	footerModule,
	documentRequestModalModule,
	serviceUpdatesModalModule,
	fieldLogModalComponent,
	fieldLogListModalComponent
];

export default angular.module('index.components', components.map(c => c.name));
