
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('FieldLogDocument', function (Parse) {

		let schema = [
			'label',
			'original_name',
			'size',
			'type',
			'url',
		];

		let FieldLogDocument = Parse.Object.extend('ProductFieldLogDocument', {
			// Instance methods

		}, {
			// Class methods

		});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(FieldLogDocument.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return FieldLogDocument;

	});
}

