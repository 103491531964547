
/* injects from baggage-loader */

'use strict';

export default function (app) {
  app.service('User', function (Parse, $state, $q, $log) {

    let schema = [
      'name',
      'email',
      'username',
      'company',
      'company_name',
      'company_id',
      'company_role',
      'account_role',
      'invitation',
      'sendDocumentRequestEmails',
      'isPrimaryUser'
    ];

    let User = Parse.User.extend({
      // Instance methods
    }, {
      // Class methods

      /**
       * Returns true if a User is currently logged in
       */
      isUserLoggedIn: function () {
        return this.current() ? true : false;
      },

      getSessionToken: function () {
        if (this.isUserLoggedIn()) {
          return this.current().getSessionToken()
        }
      },

      isAccountOwner: function () {
        return this.isUserLoggedIn()
          ? this.current().account_role === 'admin' || this.current().account_role === 'owner'
          : false;
      },

      /**
       * Authenticate user login credentials and grant access to the portal if successful
       */
      authenticate: function (user) {

        let d = $q.defer();

        // Contact the server and attempt to authenticate the User
        Parse.User.logIn(user.email, user.password)
          .then(function (_user) {
            // Mark last login attempt successful
            // ServerEvent.markLoginSuccessful();
            // Login successful, return user
            d.resolve(_user);
          })
          .catch(err => {
            // Login failed, return error
            d.reject(err);
          });

        return d.promise;

      },


      /**
       * Create a new User account
       */
      signup: function (user) {

        let d = $q.defer();

        // Check for current user, reject signup if true
        if (this.isUserLoggedIn()) {
          return d.reject('Cannot process signup, a user is already logged in.');
        }

        // Create a new Parse User object
        let newUser = new Parse.User({
          username: user.email,
          password: user.password,
          email: user.email,
          company_name: user.company_name
        });

        // Contact the server and tell it we want to create a new User
        newUser.signUp()
          .then(async _user => {

            // Record the server event
            // await ServerEvent.recordEvent('login-attempt', {
            //   username: _user.get('username'),
            //   success: true
            // });

            // Signup successful, mark as a successful "login-attempt"
            // ServerEvent.markLoginSuccessful();

            // Return the user object
            d.resolve(_user);

          })
          .catch(err => {
            // Signup failed, return error
            d.reject(err);
          });

        return d.promise;

      },


      /**
       * Logout current User
       */
      logout: function () {
        this.logOut()
          .then(user => {
            // $state.go('login');
            $state.reload();
          })
          .catch(err => {
            $log.error(err);
            $state.go('login');
          });
      },


      /**
       * Send an email to the user with instructions to reset their password
       */
      resetPassword: function (email) {

        let d = $q.defer();

        this.requestPasswordReset(email)
          .then(() => {
            d.resolve(true);
          })
          .catch(err => {
            d.reject(err);
          });

        return d.promise;

      },

      /**
       * Returns an array of account authorized users
       */
      fetchAuthorized: function (options = {}) {
        let d = $q.defer();
        let query = new Parse.Query(this);
        query.ascending('createdAt');
        for (let key in options) {
          if (typeof options[key].key !== 'undefined' && typeof options[key].value !== 'undefined') {
            query[key](options[key].key, options[key].value);
          } else {
            query[key](options[key]);
          }
        }
        d.resolve(query.find());
        return d.promise;
      }

    });

    // Define object properties
    schema.forEach(function (field) {
      Object.defineProperty(User.prototype, field, {
        get: function () { return this.get(field); },
        set: function (value) { return this.set(field, value); }
      });
    });

    return User;

  });
}

