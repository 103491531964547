
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('DocumentRequest', function ($log, Parse, Activity) {
		
		let schema = [
			'status',
			'name',
			'company',
			'email',
			'message',
			'reason'
		];
		
		let DocumentRequest = Parse.Object.extend('DocumentRequest', {
			// Instance methods

		}, {
				// Class methods

				submitDocumentRequest(doc, product, form, options = {}) {
					$log.log(doc, product, form);
					let docRequest = new DocumentRequest(form);
					docRequest.set('document', doc.toPointer());
					docRequest.set('product', product.toPointer());
					if (options.event_id) {
						let root_event = new Activity({ id: options.event_id });
						docRequest.set('root_event', root_event.toPointer());
					}
					docRequest.setACL(product.getACL());
					return docRequest.save();
				}

			});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(DocumentRequest.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return DocumentRequest;

	});
}

