
/* injects from baggage-loader */

'use strict';

export default class MyGroupsController {

	constructor($log, $state, $window) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$window = $window;

	}

	$onInit() {
		this.$log.debug(this.groups)
	}

	openGroupLink(group) {
		let route = group.get('security') === 'public' ? 'group' : 'securegroup';
		return `${this.$window.location.origin}/${route}/${group.id}`;
	}

}
