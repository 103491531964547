
/* injects from baggage-loader */

'use strict';

import ServiceUpdatesModalComponent from './serviceupdatesmodal.component';
import './serviceupdatesmodal.scss';

const ServiceUpdatesModalModule = angular.module('service-updates-modal-module', []);

ServiceUpdatesModalModule
    .component('serviceUpdatesModal', new ServiceUpdatesModalComponent());

export default ServiceUpdatesModalModule;

