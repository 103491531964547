
/* injects from baggage-loader */

'use strict';

import DocumentRequestModalComponentController from './documentrequestmodalcomponent.controller';
import DocumentRequestModalComponentTemplate from './documentrequestmodalcomponent.html';

export default class DocumentRequestModalComponentComponent {
	constructor() {
		this.templateUrl = DocumentRequestModalComponentTemplate;
		this.controller = DocumentRequestModalComponentController;
		this.controllerAs = 'ctrl';
		// Bind resolved items to the controller, see module state for resolve
		this.bindings = {
			resolve: '<',
			close: '&',
			dismiss: '&'
		};
	}
}
