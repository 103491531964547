
/* injects from baggage-loader */

'use strict';

export default class CodeNotFoundController {

	constructor($log, $stateParams) {
		'ngInject';

		this.$log = $log;
		this.$stateParams = $stateParams;

		this.code_id = $stateParams.id;

	}

	$onInit() {

	}

}
