
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('FieldLog', function (Parse) {

		let schema = [
			'name',
			'email',
			'company_name',
			'note',
			'product',
			'has_attachments',
			'documents'
		];

		let FieldLog = Parse.Object.extend('ProductFieldLog', {
			// Instance methods

		}, {
			// Class methods

			find(product) {
				return product.relation('fieldLogs')
					.query()
					.limit(1000)
					.descending('createdAt')
					.find();
			},

			/**
			 * Retrieve a FieldLog by ID
			 */
			get(id, options = {}) {
				const query = new Parse.Query(this);
				if (typeof options.include !== 'undefined') {
					query.include(options.include);
				}
				return query.get(id);
			},

		});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(FieldLog.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return FieldLog;

	});
}

