
/* injects from baggage-loader */

'use strict';

import config from './index.config';
import run from './index.run';

import uiRouter from '@uirouter/angularjs';

import coreModule from './core/core.module';
import indexComponents from './index.components';
import indexRoutes from './index.routes';

// Import ui bootstrap modules
import alert from 'ui-bootstrap4/src/alert';
import modal from 'ui-bootstrap4/src/modal';
import collapse from 'ui-bootstrap4/src/collapse';
import dropdown from 'ui-bootstrap4/src/dropdown';
import progressbar from 'ui-bootstrap4/src/progressbar';

// IMPORT PAGE MODULES

// Product 
import productModule from './pages/product/product.module';
import codeNotFoundModule from './pages/codeNotFound/codenotfound.module';
import recentlyViewedModule from './pages/recently-viewed/recently-viewed.module';
import fieldLogViewerModule from './pages/viewFieldLog/viewfieldlog.module';

// Account
import loginModule from './pages/login/login.module';
import passwordResetModule from './pages/password-reset/password-reset.module';
import productGroupModule from './pages/group/group.module';
import myGroupsModule from './pages/my-groups/my-groups.module';
import bookmarksModule from './pages/bookmarks/bookmarks.module';
import settingsModule from './pages/settings/settings.module';
import invitationsModule from './pages/invitations/invitations.module';

// Actions
import acceptInviteModule from './pages/actions/accept-invite/actions.accept-invite.module';

const App = angular.module("hrscWeb",
	[
		// plugins
		uiRouter,
		"ngAnimate",
		"ngCookies",
		"ngTouch",
		"ngSanitize",
		"ngMessages",
		"ngAria",
		"ngResource",
		'LocalStorageModule',
		"ngFileUpload",

		alert,
		modal,
		collapse,
		dropdown,
		progressbar,

		// core
		coreModule.name,

		// components
		indexComponents.name,

		// pages
		productModule.name,
		codeNotFoundModule.name,
		productGroupModule.name,
		loginModule.name,
		passwordResetModule.name,
		myGroupsModule.name,
		recentlyViewedModule.name,
		bookmarksModule.name,
		settingsModule.name,
		invitationsModule.name,
		fieldLogViewerModule.name,

		// routes
		indexRoutes.name,

		// Action pages
		acceptInviteModule.name,

	]
);

App
	.config(config)
	.run(run);

export default App;