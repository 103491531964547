
/* injects from baggage-loader */

'use strict';

export default class ViewFieldLogController {

	constructor($log) {
		'ngInject';

		this.$log = $log;

		this.productInfo = {};
		this.fieldLog = {};
		this.attachments = [];

	}

	$onInit() {

		this.$log.debug('Product Info:', this.productInfo);
		this.$log.debug('FieldLog:', this.fieldLog);
		this.$log.debug('Attachments:', this.attachments);

	}

	getAttachmentUrl(file) {
		return file.get('url');
	}

}
