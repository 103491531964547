
/* injects from baggage-loader */

'use strict';

export default class LoginController {

	constructor($log, $state, $location, $stateParams, $timeout, User, Navigation) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$location = $location;
		this.$timeout = $timeout;
		this.$stateParams = $stateParams;
		this.User = User;
		this.Navigation = Navigation;

		this.user = {};
		this.logging_in = false;

	}

	$onInit() {

		if (this.$stateParams.email) {
			this.user.email = this.$stateParams.email;
		}

	}

	/**
     * Attempts to authenticate the User and log them into the app
     */
	login() {

		// Clear any error
		this.login_error = '';
		this.logging_in = true;

		// Show progress indicator

		this.User.authenticate(this.user)
			.then(user => {

				// this.$log.debug(user);
				if (this.Navigation.getIntendedPath()) {

					// Go to intended path post login
					this.$location.path(this.Navigation.getIntendedPath(true));

				} else {

					// Go to dashboard
					this.$state.go('my-groups');

				}

			})
			.catch(err => {
				// Show login error
				this.login_error = err.message || err;
				this.logging_in = false;
				this.$timeout();
			});

	}

}
