
/* injects from baggage-loader */

'use strict';

function runBlock($log, $rootScope, $timeout, $window, $transitions, DEFAULT_PAGE_TITLE, Parse, User, Navigation, $location, Alerts) {
	'ngInject';

	// NOTE: TEMPALTE REPLACEMENT HANDLED BY WEBPACK, DON'T EDIT MANUALLY
	// Initialize parse with Application key only
	Parse.initialize('hrsc');

	// NOTE: TEMPALTE REPLACEMENT HANDLED BY WEBPACK, DON'T EDIT MANUALLY
	// Set server URL
	Parse.serverURL = 'https://api.hrsc.us/parse';

	// Main application state
	$rootScope.state = {
		transitions: {}
	};

	// State animation settings
	// Remove class name or set false to disable
	$rootScope.state_transitions = {
		main: false,
		child: false
	}


	// =========================================
	// Main Transition Hooks
	// =========================================


	// Called when any state transition is about to begin
	$transitions.onStart({}, transition => {
		// Do stuff when the state transition begins

		// Redirect to login page if route is protected and no current user
		// TODO: add logic to recognize auth token as a valid user
		if (transition.to().protected === true && User.isUserLoggedIn() === false) {

			// Grab the intended path and save it
			Navigation.setIntendedPath($location.path());

			// Add alert to what happened to global alerts
			Alerts.add({
				type: 'info',
				msg: 'Please log in to perform this action.',
				timeout: 60000
			});

			$location.path('/login');
			return transition.router.stateService.target('login');
		}

		// Listen for notifying transitions
		if (transition.options().notify !== false) {

			// Check if to state is set as previous in state data
			let previous = transition.from().data ? transition.from().data.previous : '';

			// Update state transition flags
			$rootScope.state.transitions.back = transition.to().name === previous;
			$rootScope.state.transitions.toHome = (transition.to().name === 'home');

		}

	});

	// Called when any state transition is entered
	$transitions.onEnter({}, (transition, state) => {
		// Clear any alerts
		Alerts.clear()
	});

	// Called when any state transition succeeds
	$transitions.onSuccess({}, transition => {

		$log.debug(transition.to())

		// Update document title
		$timeout(() => {
			if (typeof transition.to().title !== 'undefined' && transition.to().title.length > 0) {
				// Set document title for the current page
				$window.document.title = `${DEFAULT_PAGE_TITLE} | ${transition.to().title}`;
			} else {
				// Set the default page title
				$window.document.title = DEFAULT_PAGE_TITLE;
			}
		}, 250);

	});

	// Called when any state transition encounters an error
	$transitions.onError({}, (transition, state) => {
		// Handle transition errors
		$log.error('error', transition._error)

		let error = transition._error ? transition._error.detail : transition._error;

		if (error && error.code === 209) {

			// Add alert to what happened to global alerts
			Alerts.add({
				type: 'danger',
				msg: 'Session expired.',
				timeout: 60000
			});

			User.logOut();
			$location.path('/login');
			return transition.router.stateService.target('login');
		}

	});

}

export default runBlock;