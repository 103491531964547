
/* injects from baggage-loader */

'use strict';

import FieldLogListModalController from './fieldloglistmodal.controller';
import FieldLogListModalTemplate from './fieldloglistmodal.html';

export default class FieldLogListsModalComponent {
		constructor() {
				this.templateUrl = FieldLogListModalTemplate;
				this.controller = FieldLogListModalController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					resolve: '<',
					close: '&',
					dismiss: '&'
				};
		}
}
