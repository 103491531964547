
/* injects from baggage-loader */

'use strict';

import DocumentRequestModalComponentComponent from './documentrequestmodalcomponent.component';
import './documentrequestmodalcomponent.scss';

const DocumentRequestModalComponentModule = angular.module('document-request-modal-component-module', []);

DocumentRequestModalComponentModule
    .component('documentRequestModalComponent', new DocumentRequestModalComponentComponent());

export default DocumentRequestModalComponentModule;

