
/* injects from baggage-loader */

'use strict';

export default class DocumentRequestModalComponentController {

	constructor($log, $timeout, ProductDocument, DocumentRequest) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.ProductDocument = ProductDocument;
		this.DocumentRequest = DocumentRequest;

		this.modal_state = 'home';
		// this.modal_state = 'request-access';
		// this.modal_state = 'request-recieved';

		this.document_key_length = 8;
		this.document_key = '';
		this.validating_code = false;
		this.invalid_code = false;
		this.verifications;

		this.request_form = {};
		this.submitting_request = false;

	}

	$onInit() {

		// Set modal context
		this.context = this.resolve.context;

		this.$log.debug(this.context);

	}

	nextState(state) {
		this.modal_state = state;
	}

	keyLengthOkay() {
		return this.document_key.length >= this.document_key_length;
	}

	unlockDocument() {

		this.invalid_code = false;
		this.validating_code = true;

		if (!this.document_key.length || this.document_key.length < this.document_key_length) {
			this.invalid_code = true;
			this.validating_code = false;
			return false;
		}

		// Check if the entered key is valid
		this.ProductDocument.verifyKey(this.context.document_id, this.document_key)
			.then(res => {

				this.validating_code = false;
				this.$timeout(angular.noop, 10);

				if (res.verified) {
					// Close the modal and return the document key and id
					this.close({
						$value: {
							document_id: this.context.document_id,
							document_key: this.document_key,
							key_hash: res.key_hash,
							sig: res.signature
						}
					});
				} else {
					// Invalid code, display error alert
					this.invalid_code = true;
				}

			})
			.catch(err => {

				this.invalid_code = true;
				this.validating_code = false;
				this.$timeout(angular.noop, 10);

				alert('Rate limit reached, please wait 1 minute before attempting to verify another key');

			})

	}


	// TODO: function used to unlock multiple document from 1 key
	// unlockDocument() {

	// 	this.invalid_code = false;
	// 	this.validating_code = true;

	// 	// Check if the entered key is valid
	// 	this.ProductDocument.verifyKey(this.context.ids, this.document_key)
	// 		.then(res => {

	// 			this.validating_code = false;
	// 			this.$timeout(angular.noop, 10);

	// 			// Set verifications
	// 			this.verifications = res;

	// 			// Check that at least 1 document key was verified


	// 			// Move to the next state to review document verifications
	// 			this.nextState('review-documents');



	// 		});

	// }

	validateEmail(val) {
		if (!val.match(/\S+@\S+\.\S+/)) { // Jaymon's / Squirtle's solution
			// Do something
			return false;
		}
		if (val.indexOf(' ') !== -1 || val.indexOf('..') !== -1) {
			// Do something
			return false;
		}
		return true;
	}

	submitDocumentRequest(form) {

		this.request_form_error = '';	

		this.$log.debug(this.request_form);
		this.$log.debug(this.context.document, this.context.product)

		this.$log.debug('Controls:', form.$$controls, form.$getControls());
		this.$log.debug('Valid:', form.$valid);

		// Trim all fields
		for (let key in this.request_form) {
			if (this.request_form[key]) {
				this.request_form[key] = this.request_form[key].trim();
			}
		}

		// Verify email address is valid
		if (!this.request_form.email || !this.validateEmail(this.request_form.email)) {
			this.request_form_error = 'A valid email address is required';
			return false;
		}

		this.submitting_request = true;

		let documentObj = new this.ProductDocument({ id: this.context.document.id || this.context.document.objectId })

		this.DocumentRequest.submitDocumentRequest(documentObj, this.context.product, this.request_form, {
			event_id: this.context.event_id
		})
			.then(res => {
				this.$log.debug(res);

				this.nextState('request-recieved');
				this.submitting_request = false;
				this.$timeout(angular.noop, 10);

			})
			.catch(err => {

				this.$log.error(err);
				this.submitting_request = false;
				this.request_form_error = 'Something went wrong, but don\'t worry our tech team has been alerted. Please wait a bit before tring this action again.';	
				this.$timeout(angular.noop, 10);
				
			})

	}

	ok() {
		this.close({ $value: true });
	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
