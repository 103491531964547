
/* injects from baggage-loader */

'use strict';

export default function (app) {

	app.constant('APP_VERSION', '1.2.2');

	// Default page name displayed in the browser tab
	app.constant('DEFAULT_PAGE_TITLE', 'Human Rights Supply Chain');

	app.constant('FILE_UPLOAD_URL', 'https://storage.hrsc.us/upload');
	
}