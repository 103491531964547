
/* injects from baggage-loader */

'use strict';

import RecentlyViewedComponent from './recently-viewed.component';

const RecentlyViewedPageModule = angular.module('recently-viewed-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('');

    $stateProvider
      .state('recently-viewed', {
        url: '/recently-viewed',
        component: 'recentlyViewed',
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          items: function (RecentlyViewed) {
            return RecentlyViewed.getList()
          }
        }
      });
  })
  .component('recentlyViewed', new RecentlyViewedComponent());

export default RecentlyViewedPageModule;
