
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Product', function (Parse, $q, $log) {

		let schema = [
			'name',
			'image',
			'company',
			'documents',
			'service_email',
			'product_page',
			'videos',
			'document_order',
			'service_updates',
			'model_num',
			'description'
		];

		let Product = Parse.Object.extend('Product', {
			// Instance methods

		}, {
				// Class methods


				/**
				 * Retrieve a product by ID
				 */
				get(id) {
					return Parse.Cloud.run('fetchProduct', { id })
						// .then(res => {
						// 	// Set document urls
						// 	if (res && res.documents) {
						// 		for (let doc of res.documents) {
						// 			if (!doc.url) {
						// 				doc.url = `https://storage.machinedocument.com/${doc.protected ? 'private' : 'public'}/${doc.id}`
						// 			}
						// 		}
						// 	}
						// 	return res;
						// })
				},

				getBasicProductInfo(id) {
					return Parse.Cloud.run('getBasicProductInfo', { id });
				}


			});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(Product.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return Product;

	});
}

