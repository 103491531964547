
/* injects from baggage-loader */

'use strict';

export default class ActionsAcceptInviteController {

	constructor($log, $state, $timeout, $interval, Parse, User, Alerts) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$timeout = $timeout;
		this.$interval = $interval;
		this.Parse = Parse;
		this.User = User;
		this.Alerts = Alerts;

		this.acceptingInvitation = false;
		this.processing = false;
		this.processingMessage = '';

		this.creating_account = false;
		this.signup_error = null;

		this.user = {};
		this.existingUser = false;
		this.isCurrentUser = false;

		this.redirectCountdown = 5;
		this.redirectCountdownInterval;

	}

	async $onInit() {

		if (this.invitation) {
			this.user.email = this.invitation.email;
		} else {
			this.signup_error = 'Invitation not found';
			return false;
		}

		this.existingUser = this.invitation.get('user') ? true : false;
		this.$log.debug('Existing user:', this.existingUser)

		// Check if there is a current user and it matches the user defined in the invitation
		if (this.User.isUserLoggedIn() && !this.existingUser) {
			// Call Parse.user.logOut() method as not to return the user back to the login page
			this.User.logOut();
			return false;
		}

		// Current user exists and invited existing user, but not the same current user
		if (this.User.isUserLoggedIn() && this.existingUser && this.User.current().id !== this.invitation.get('user').id) {
			// Call Parse.user.logOut() method as not to return the user back to the login page
			this.User.logOut();
			return false;
		}
		

		// Current user is the same as invited existing user
		// Accept the invite and take the user to the product group page
		if (this.User.isUserLoggedIn() && this.existingUser && this.User.current().id === this.invitation.get('user').id) {
			// Display accept button
			this.isCurrentUser = true;
		}

	}

	$onDestroy() {
		if (this.redirectCountdownInterval) {
			this.$interval.cancel(this.redirectCountdownInterval)
		}
	}

	getProductGroupText() {
		return `has invited you to and access their "${this.group.get('name')}" product group.`;
	}

	redirectUser() {
		this.acceptingInvitation = true;
		this.processing = false;
		this.$timeout();
		// Redirect user to page after 5 seconds
		this.$timeout(() => this.$state.go('securegroup', { id: this.group.id }), 5000);
		this.redirectCountdownInterval = this.$interval(() => this.redirectCountdown -= 1, 1000);
	}

	async acceptInvite() {

		this.creating_account = true;

		// Accept account invitation 
		this.Parse.Cloud.run('acceptAccountInvite', {
			invitationId: this.invitation.id
		})
			.then(() => {
				this.Alerts.add({ type: 'success', msg: 'Invitation accepted' });
				this.$state.go('securegroup', { id: this.group.id })
			})

	}

	async signup() {

		this.signup_error = null;

		if (!this.user.email) {
			this.signup_error = 'Missing "email"';
			return;
		}
		if (!this.user.password || !this.user.password_verify) {
			this.signup_error = 'Missing "password"';
			return;
		}
		if (this.user.password !== this.user.password_verify) {
			this.signup_error = 'The passwords do not match';
			return;
		}

		this.creating_account = true;

		try {

			// Accept account invitation and create user
			let newUser = await this.Parse.Cloud.run('acceptAccountInvite', {
				user: this.user,
				invitationId: this.invitation.id
			});

			await this.User.authenticate({
				email: this.user.email,
				password: this.user.password
			})

			this.redirectUser();

		} catch (err) {
			this.creating_account = false;
			this.signup_error = err.toString().replace('ParseError: 141', '');
			this.$timeout()
		}

	}

}
