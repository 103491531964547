
/* injects from baggage-loader */

// Load font awesome core
import { library, dom } from "@fortawesome/fontawesome-svg-core";

// Solid icons
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons/faGlobeAmericas";
import { faWrench } from "@fortawesome/free-solid-svg-icons/faWrench";
import { faBook } from "@fortawesome/free-solid-svg-icons/faBook";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons/faCloudDownloadAlt";
import { faFolder } from "@fortawesome/free-solid-svg-icons/faFolder";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons/faFolderOpen";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faUnlock } from "@fortawesome/free-solid-svg-icons/faUnlock";
import { faThList } from "@fortawesome/free-solid-svg-icons/faThList";
import { faThLarge } from "@fortawesome/free-solid-svg-icons/faThLarge";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons/faPaperclip";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import { faCertificate } from "@fortawesome/free-solid-svg-icons/faCertificate";

// TODO: update font awesome packages to 5.2 for faFileCertificate
 
// Brand icons
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";

// Set config options
// config.replacementClass = '';
// config.observeMutations = true;


// Add icons to the library
library.add(
  
  // Solids
  faEllipsisV, faGlobeAmericas, faWrench, faBook, faEnvelope, faChevronLeft, faChevronRight, faCloudDownloadAlt,
  faFolderOpen, faFolder, faUnlock, faLock, faThList, faThLarge, faPaperclip, faCertificate,

  // Regular
  faNewspaper,

  // Brands
  faYoutube, faFacebookF, faInstagram, faTwitter
);
dom.watch();

// Show the svg data for the icon
// Useful for creating .svg files and importing directly
// const plus = findIconDefinition({iconName: 'plus'})
// console.log(icon(faTimes).html)