
/* injects from baggage-loader */

'use strict';

function routeConfig($urlRouterProvider, $locationProvider) {
	'ngInject';
	
	// Default route
	$urlRouterProvider.otherwise('/recently-viewed');
	$locationProvider.html5Mode(true);

}

export default angular
  .module('index.routes', [])
  .config(routeConfig);