
/* injects from baggage-loader */

'use strict';

import CodeNotFoundComponent from './codenotfound.component';

const CodeNotFoundPageModule = angular.module('code-not-found-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    // $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('codenotfound', {
        url: '/not-found?id',
        component: 'codeNotFound',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('codeNotFound', new CodeNotFoundComponent());

export default CodeNotFoundPageModule;
