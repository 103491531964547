
/* injects from baggage-loader */

'use strict';

import ViewFieldLogComponent from './viewfieldlog.component';

const ViewFieldLogPageModule = angular.module('view-field-log-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('viewfieldlog', {
        url: '/company/:productId/updates/:fieldLogId',
        component: 'viewFieldLog',
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          fieldLog: function ($transition$, FieldLog) {
            const id = $transition$.params('to').fieldLogId;
            return FieldLog.get(id, {
              include: ['product']
            });
          },
          productInfo: function($transition$, Product) {
            const id = $transition$.params('to').productId;
            return Product.getBasicProductInfo(id);
          },
          attachments: function (fieldLog) {
            return fieldLog.relation('documents')
              .query()
              .select(['url'])
              .limit(10)
              .find();
          }
        }
      });
  })
  .component('viewFieldLog', new ViewFieldLogComponent());

export default ViewFieldLogPageModule;
